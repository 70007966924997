export const MAP_STATUS_TRANSFER_REQUESTS = {
    'transfer_request' : {name: 'Solicitud de traslado', color: 'orange darken-2', key: "transfer_request"},
    'transfer': {name: 'Transferencia', color: 'primary', key: "transfer"},
    'reception': {name: 'Recepcionado', color: 'success', key: "reception"},    
}

export const MAP_STATUS_DISPATCH_TRANSFER_REQUEST = [
    {
        id: null,
        name: "Todo"
    },
    {
        id: true,
        name: "Despachado"
    },
    {
        id: false,
        name: "Sin despachar"
    },
]